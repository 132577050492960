import React from "react";
import Web3 from "web3";
import axios from "axios";
import logo from "./LogoBackground.png";
import ReactPlayer from "react-player";
import ABI from "./ABI.js";
import opensea from "./opensea.png";
import twitter from "./twitter.png";
import Wallet from "./Wallet.js";
import "./App.css";

function App() {
  let [total, setTotal] = React.useState(null);
  let [price, setPrice] = React.useState(0);
  let [lookup, setLookup] = React.useState({
    1: { type: "unique", name: "", total_colors: 1, colors: {}, color_array: [] },
    2: { type: "unique", name: "", total_colors: 1, colors: {}, color_array: [] },
    3: { type: "unique", name: "", total_colors: 1, colors: {}, color_array: [] },
    4: { type: "epic", name: "", total_colors: 2, colors: {}, color_array: [] },
    5: { type: "epic", name: "", total_colors: 3, colors: {}, color_array: [] },
    6: { type: "rare", name: "", total_colors: 4, colors: {}, color_array: [] },
    7: { type: "rare", name: "", total_colors: 4, colors: {}, color_array: [] },
    8: { type: "uncommon", name: "", total_colors: 6, colors: {}, color_array: [] },
    9: { type: "uncommon", name: "", total_colors: 8, colors: {}, color_array: [] },
    10: { type: "common", name: "", total_colors: 10, colors: {}, color_array: [] },
    11: { type: "common", name: "", total_colors: 11, colors: {}, color_array: [] },
  });
  let [targetUrl, setTargetUrl] = React.useState(null);
  let [isPlaying, setIsPlaying] = React.useState(false);
  let [web3, setWeb3] = React.useState(null);
  let [userAddress, setUserAddress] = React.useState(null);
  let [userTokens, setUserTokens] = React.useState(null);

  const unlockWeb3 = () => {
    window.ethereum
      .request({ method: "eth_requestAccounts" })
      .then((result) => {
        setUserAddress(result[0]);
        setWeb3(new Web3(window.ethereum));
      })
      .catch((error) => {
        console.log("eth_requestAccounts error", error);
      });
  };

  React.useEffect(() => {
    axios.get("https://ethershards.com/unlocked/all").then((result) => {
      let unlocked = result.data;
      console.log(unlocked);
      setLookup(unlocked.lookup);
      setPrice(unlocked.price);
      setTotal(unlocked.total);
    });
  }, []);

  React.useEffect(() => {
    if (typeof window.ethereum !== "undefined") {
      window.ethereum
        .request({ method: "eth_requestAccounts" })
        .then((result) => {
          let user_address = result[0];
          setUserAddress(user_address);
          const web3 = new Web3(window.ethereum);
          setWeb3(web3);
        })
        .catch((error) => {
          console.log("eth_requestAccounts error", error);
        });
    } else {
      console.log("no web3 detected");
    }
  }, []);

  const onShardClick = (url) => {
    setTargetUrl(url);
    if (isPlaying) {
      setIsPlaying(false);
    } else {
      setIsPlaying(true);
    }
  };
  const isShardPlaying = (url) => {
    if (isPlaying) {
      console.log(url, targetUrl);
      if (url === targetUrl) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const mint = async (id, wei_value) => {
    if (userAddress === null) {
      await unlockWeb3();
    }

    var NFTContract = new web3.eth.Contract(ABI, "0x8D5431038309CD2efbd0598863d2d5421c165004");
    NFTContract.methods
      .mint()
      .send({ from: userAddress, value: price * 1000000000000000000, gas: 300000 })
      .on("transactionHash", (hash) => {
        console.log(hash);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const renderShard = (isUnlocked, name, size, color, url) => {
    if (!isUnlocked) {
      //border rounded-md border-gray-200 bg-gray-50
      return (
        <div class={`flex flex-col mt-8 m-auto sm:m-0 sm:mt-8 `} style={{ padding: "18px" }}>
          <div
            class={`${
              size === "large" ? "w-64 h-64" : "w-32 h-32"
            } bg-gray-100 border-t border-r border-l border-gray-200 shadow rounded-sm flex justify-center items-center`}
          >
            <div class="cursor-default	 bg-clip-text text-transparent font-bold text-lg bg-gradient-to-r from-gray-600 via-gray-600 to-gray-500 ">
              Undiscovered
            </div>
          </div>
        </div>
      );
    } else {
      //border rounded-md border-gray-200 bg-gray-50
      return (
        <div onClick={() => onShardClick(url)} class="cursor-pointer flex flex-col mt-8 m-auto sm:m-0 sm:mt-8 " style={{ padding: "18px" }}>
          <ReactPlayer playing={isShardPlaying(url)} width="256px" height="256px" url={url} loop={true} />

          <div class=" flex w-full justify-center mt-4 font-bold ">
            <div class="w-full flex-row items-center justify-center">
              <div class="text-center cursor-pointer bg-clip-text text-transparent text-lg bg-gradient-to-r from-gray-500 via-gray-500 to-gray-500  hover:from-gray-600 hover:via-gray-600 hover:to-gray-500 ">
                {name}
              </div>
              <div class="text-center cursor-pointer bg-clip-text text-transparent text-md bg-gradient-to-r from-gray-500 via-gray-500 to-gray-500  hover:from-gray-600 hover:via-gray-600 hover:to-gray-500 ">
                {color}
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  const renderSet1 = lookup["1"].color_array.map((item) => {
    return renderShard(item.isUnlocked, lookup["1"].name, "large", item.color, item.url);
  });
  const renderSet2 = lookup["2"].color_array.map((item) => {
    return renderShard(item.isUnlocked, lookup["2"].name, "large", item.color, item.url);
  });
  const renderSet3 = lookup["3"].color_array.map((item) => {
    return renderShard(item.isUnlocked, lookup["3"].name, "large", item.color, item.url);
  });
  const renderSet4 = lookup["4"].color_array.map((item) => {
    return renderShard(item.isUnlocked, lookup["4"].name, "large", item.color, item.url);
  });
  const renderSet5 = lookup["5"].color_array.map((item) => {
    return renderShard(item.isUnlocked, lookup["5"].name, "large", item.color, item.url);
  });
  const renderSet6 = lookup["6"].color_array.map((item) => {
    return renderShard(item.isUnlocked, lookup["6"].name, "large", item.color, item.url);
  });
  const renderSet7 = lookup["7"].color_array.map((item) => {
    return renderShard(item.isUnlocked, lookup["7"].name, "large", item.color, item.url);
  });
  const renderSet8 = lookup["8"].color_array.map((item) => {
    return renderShard(item.isUnlocked, lookup["8"].name, "large", item.color, item.url);
  });
  const renderSet9 = lookup["9"].color_array.map((item) => {
    return renderShard(item.isUnlocked, lookup["9"].name, "large", item.color, item.url);
  });
  const renderSet10 = lookup["10"].color_array.map((item) => {
    return renderShard(item.isUnlocked, lookup["10"].name, "large", item.color, item.url);
  });
  const renderSet11 = lookup["11"].color_array.map((item) => {
    return renderShard(item.isUnlocked, lookup["11"].name, "large", item.color, item.url);
  });

  return (
    <div class="min-h-screen bg-white">
      <nav class="bg-white border-b border-gray-200">
        <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div class="flex justify-between h-16">
            <div class="flex">
              <div class="flex-shrink-0 flex items-center">
                <img class="block h-8 w-auto" src={logo} alt="EtherShards" />
                <div class="inline text-xl font-bold text-gray-900 ml-2">EtherShards</div>
              </div>
            </div>
            <div class="flex">
              <div class="flex-shrink-0 flex items-center">
                <a href="https://opensea.io/collection/ethershards" target="_blank">
                  <img src={opensea} class="h-8 w-8" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </nav>

      <div class="py-10">
        <main>
          <div class="relative mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
            <div class="flex mt-12 flex-col items-center lg:flex-row lg:justify-between">
              <div class="relative" style={{ maxWidth: "492px" }}>
                <div class="inline text-2xl font-extrabold text-gray-900 tracking-tight sm:text-3xl">Music X Art Collectibles</div>
                <p class="mt-3 text-lg text-gray-500">
                  Each shard consists of an original piece of music and art. Shards can be combined together in order to create new original
                  musical pieces.
                </p>
                <p class="mt-3 text-lg text-gray-500">
                  Shards are split into 5 tiers, depending on rarity. Whenever a new shard gets unlocked, it'll show up on this page, so you
                  can always see which shards haven't been discovered yet.
                </p>
                <p class="mt-3 text-lg text-gray-500">
                  You can view all currently minted shards on{" "}
                  <a class="inline text-blue-400" href="https://opensea.io/collection/ethershards" target="_blank">
                    opensea
                  </a>
                  . Shard price increases by 1% for each additional shard minted.
                </p>
                <div class="flex flex-col justify-between items-start mt-8 text-2xl font-bold">
                  <div class="mb-4 mt-1 text-3xl">Shards Left: {348 - total} / 348</div>
                  <button
                    onClick={() => mint()}
                    class="cursor-pointer antialiaised mb-4 font-bold text-xl text-gray-800 border-2 border-gray-600 p-1  rounded"
                  >
                    Mint Now
                  </button>
                </div>
              </div>

              <div class="mt-6 m-2 sm:m-0 sm:mt-12 lg:mt-10 sm:-mx-4 relative " aria-hidden="true">
                <div
                  onClick={() => mint()}
                  class="cursor-pointer font-bold text-8xl absolute text-gray-200"
                  style={{ zIndex: 100, transform: "translate(180px, 145px)" }}
                >
                  ?
                </div>
                <img onClick={() => mint()} class="cursor-pointer" src={logo} style={{ height: "400px", width: "400px  " }} />
                <div class="flex justify-between mt-2">
                  <div class="antialiaised font-bold text-xl text-gray-500">
                    Current Price: <div class="inline text-gray-800">{price.toFixed(3)} ETH</div>
                  </div>
                  <button onClick={() => mint()} class="cursor-pointer antialiaised font-bold text-xl text-gray-800  rounded">
                    Mint Now
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
            <div class="pt-16 bg-white overflow-hidden  ">
              <div class="relative ">
                <div class="relative  ">
                  <div class="mx-auto ">
                    <div class="">
                      <div class="pt-4  space-y-5 sm:space-y-4 md:max-w-xl lg:max-w-3xl xl:max-w-none">
                        <h2 class="text-3xl font-extrabold tracking-tight sm:text-4xl">Unique</h2>
                        <p class="text-xl text-gray-500">
                          {"The only shards that have no color variations. Click on a discovered shard to preview its music."}
                        </p>
                      </div>
                      <div class="pb-8 flex-wrap flex flex-row justify-between ">
                        {renderSet1}
                        {renderSet2}
                        {renderSet3}
                      </div>

                      <div class="pt-8  space-y-5 sm:space-y-4 md:max-w-xl lg:max-w-3xl xl:max-w-none">
                        <h2 class="text-3xl font-extrabold tracking-tight sm:text-4xl">Epic</h2>
                        <p class="text-xl text-gray-500">
                          {"These are shards that have very few color variations. Click on a discovered shard to preview its music."}
                        </p>
                      </div>
                      <div class="pb-8 flex-wrap flex flex-row justify-between ">
                        {renderSet4}
                        {renderSet5}
                      </div>

                      <div class="pt-8  space-y-5 sm:space-y-4 md:max-w-xl lg:max-w-3xl xl:max-w-none">
                        <h2 class="text-3xl font-extrabold tracking-tight sm:text-4xl">Rare</h2>
                        <p class="text-xl text-gray-500">
                          {"These are shards that have few color variations. Click on a discovered shard to preview its music."}
                        </p>
                      </div>
                      <div class="pb-8 flex-wrap flex flex-row justify-between ">
                        {renderSet6}
                        {renderSet7}
                      </div>

                      <div class="pt-8 space-y-5 sm:space-y-4 md:max-w-xl lg:max-w-3xl xl:max-w-none">
                        <h2 class="text-3xl font-extrabold tracking-tight sm:text-4xl">Uncommon</h2>
                        <p class="text-xl text-gray-500">
                          {"These are shards that have some color variations. Click on a discovered shard to preview its music."}
                        </p>
                      </div>
                      <div class="pb-8 flex-wrap flex flex-row justify-between ">
                        {renderSet8}
                        {renderSet9}
                      </div>

                      <div class="pt-8 space-y-5 sm:space-y-4 md:max-w-xl lg:max-w-3xl xl:max-w-none">
                        <h2 class="text-3xl font-extrabold tracking-tight sm:text-4xl">Common</h2>
                        <p class="text-xl text-gray-500">
                          {"These are shards that have many color variations. Click on a discovered shard to preview its music."}
                        </p>
                      </div>

                      <div class="pb-8 flex-wrap flex flex-row justify-between ">
                        {renderSet10}
                        {renderSet11}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default App;
